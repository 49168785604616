import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import {
  casinoInfo,
  cms,
  depositMethod,
  device,
  docType,
  document,
  favouriteGame,
  flash,
  gameDemo,
  gameInfo,
  gamePlay,
  gameVendor,
  language,
  limit,
  location,
  main,
  modal,
  newsletter,
  offer,
  recentGame,
  searchResult,
  slide,
  user,
  userNotification,
  vendor,
  withdrawalMethod,
} from './modules'
import { Module } from '@/constants'

Vue.use(Vuex)

// eslint-disable-next-line no-unused-vars
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

// eslint-disable-next-line no-unused-vars
const monitorMutations = (store) => {
  store.subscribe((mutation) => {
    console.log(mutation.type, mutation.payload)
  })
}

const store = new Vuex.Store({
  modules: {
    [Module.CASINO_INFO]: casinoInfo,
    [Module.CMS]: cms,
    [Module.DEPOSIT_METHOD]: depositMethod,
    [Module.DEVICE]: device,
    [Module.DOC_TYPE]: docType,
    [Module.DOCUMENT]: document,
    [Module.FAVOURITE_GAME]: favouriteGame,
    [Module.FLASH]: flash,
    [Module.GAME_DEMO]: gameDemo,
    [Module.GAME_INFO]: gameInfo,
    [Module.GAME_PLAY]: gamePlay,
    [Module.GAME_VENDOR]: gameVendor,
    [Module.LANGUAGE]: language,
    [Module.LIMIT]: limit,
    [Module.LOCATION]: location,
    [Module.MAIN]: main,
    [Module.MODAL]: modal,
    [Module.NEWSLETTER]: newsletter,
    [Module.OFFER]: offer,
    [Module.RECENT_GAME]: recentGame,
    [Module.SEARCH_RESULT]: searchResult,
    [Module.SLIDE]: slide,
    [Module.USER]: user,
    [Module.USER_NOTIFICATION]: userNotification,
    [Module.VENDOR]: vendor,
    [Module.WITHDRAWAL_METHOD]: withdrawalMethod,
  },
  plugins: [vuexLocal.plugin, monitorMutations],
})

export default store
