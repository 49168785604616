import { SLASH, Module, CURRENT_LANGUAGE } from '@/constants'
import { getState } from './store'

export const prependLocaleToPath = ({ params, ...rest }) => {
  const locale = getState([Module.LANGUAGE, CURRENT_LANGUAGE])

  if (rest.fullPath === SLASH) {
    return `/${locale}${rest.path}`
  }

  return {
    params: { ...params, lang: locale },
    ...rest,
  }
}
