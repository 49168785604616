import { Module, UPDATE_TRANSLATION, REGEX_LANG } from '@/constants'
import { dispatchAction } from './store'
import { load } from './load'
import { navigateTo } from './navigateTo'
import router from '../router'

export const changeLanguage = async (key) => {
  const routeLang =
    REGEX_LANG.test(location.pathname) && location.pathname.substring(1, 3)

  const selectedLanguage =
    key || routeLang || localStorage.getItem('lang') || 'en'
  const currentRoute = router?.history?.current

  localStorage.setItem('lang', key || routeLang)

  await load([
    dispatchAction([Module.LANGUAGE, UPDATE_TRANSLATION], selectedLanguage),
  ])
  key &&
    navigateTo({
      name: currentRoute.name,
      params: { ...currentRoute.params, lang: key },
    })
}
