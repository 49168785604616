import {
  Module,
  CURRENT_LANGUAGE_TRANSLATION,
  DEFAULT_LANGUAGE_TRANSLATION,
} from '@/constants'
import { getGetter } from './store'

export const createComponentTranslator = (componentName) => (key) => {
  const translations = getGetter([
    Module.LANGUAGE,
    CURRENT_LANGUAGE_TRANSLATION,
  ])
  const defaultTranslations = getGetter([
    Module.LANGUAGE,
    DEFAULT_LANGUAGE_TRANSLATION,
  ])

  return (
    translations?.[componentName]?.[key] ||
    defaultTranslations?.[componentName]?.[key] ||
    'no translat.'
  )
}
