import {
  CSN_INPUT_CLASS_SELECTOR,
  EMAIL_REGEX,
  PASSWORD_REGEX,
  Type,
  FORM_ERROR,
} from '@/constants'
import {
  when,
  pipe,
  ifElse,
  isNotNumber,
  always,
  createComponentTranslator,
} from '@/helpers'

const t = createComponentTranslator(FORM_ERROR)

const validateEmail = (value) => EMAIL_REGEX.test(value) || t('enter_email')

const validatePassword = (value) =>
  PASSWORD_REGEX.test(value) || t('password_wrong')

const validateRequiredField = (value) => !!value || t('field_is_required')

const validateHasFile = (value) =>
  typeof value === Type.OBJECT || t('choose_file')

const validateCheckedCheckbox = (value) =>
  value === true || t('checkbox_must_be_checked')

const validateEquality = (fieldName, formId = null) => (value) => {
  const selector = `${CSN_INPUT_CLASS_SELECTOR}[name="${fieldName}"]`
  const parent = formId ? document.querySelector(`#${formId}`) : document

  return (
    value === parent.querySelector(selector).__vue__.value ||
    t('password_confirmation_does_not_match_password')
  )
}

const validateMeetsMinimumValue = (minValue) => (value) => {
  return pipe(
    when(isNotNumber, Number),
    ifElse(
      Number.isNaN,
      always(t('must_be_number')),
      ifElse(
        (value) => minValue > value,
        always(`${t('must_not_exceed_min')}: ${minValue}`),
        always(true),
      ),
    ),
  )(value)
}

const validateMeetsMaximumValue = (maxValue) => (value) => {
  return pipe(
    when(isNotNumber, Number),
    ifElse(
      Number.isNaN,
      always(t('must_be_number')),
      ifElse(
        (value) => value > maxValue,
        always(`${t('must_not_exceed_max')}: ${maxValue}`),
        always(true),
      ),
    ),
  )(value)
}

export const Validator = {
  checked: validateCheckedCheckbox,
  email: validateEmail,
  isSameAs: validateEquality,
  password: validatePassword,
  required: validateRequiredField,
  hasFile: validateHasFile,
  meetsMin: validateMeetsMinimumValue,
  meetsMax: validateMeetsMaximumValue,
}
