import {
  CURRENT_LANGUAGE,
  SET_CURRENT_LANGUAGE,
  UPDATE_TRANSLATION,
  CURRENT_LANGUAGE_TRANSLATION,
  DEFAULT_LANGUAGE_TRANSLATION,
  TRANSLATION,
  ResponseState,
} from '@/constants'
import { MainApi } from '@/api'

const defaultLanguage = 'en'

export const language = {
  namespaced: true,
  state: {
    [CURRENT_LANGUAGE]: localStorage.getItem('lang') || defaultLanguage,
    [TRANSLATION]: null,
  },
  mutations: {
    [SET_CURRENT_LANGUAGE](state, language) {
      state[CURRENT_LANGUAGE] = language
    },
    [UPDATE_TRANSLATION](state, translation) {
      state[TRANSLATION] = translation //NOTE: must be immutable for the getters to update on time.
    },
  },
  actions: {
    async [UPDATE_TRANSLATION]({ commit, state }, language) {
      try {
        const [currentLangResponse, defaultLangResponse] = await Promise.all([
          MainApi.getTranslations({ language }),
          language !== defaultLanguage
            ? MainApi.getTranslations({ language: defaultLanguage })
            : null,
        ])

        const {
          data: currentLangData,
          state: currentLangState,
        } = currentLangResponse
        const { data: defaultLangData, state: defaultLangState } =
          defaultLangResponse || {}

        currentLangState === ResponseState.OK &&
          commit(SET_CURRENT_LANGUAGE, language)

        commit(UPDATE_TRANSLATION, {
          ...state[TRANSLATION],
          ...(currentLangState === ResponseState.OK
            ? { [language]: currentLangData?.items }
            : {}),
          ...(defaultLangState === ResponseState.OK
            ? { [defaultLanguage]: defaultLangData?.items }
            : {}),
        })
      } catch (e) {
        console.dir(e)
      }
    },
  },
  getters: {
    [CURRENT_LANGUAGE_TRANSLATION]: (state) => {
      const currentLanguage = state[CURRENT_LANGUAGE]

      return state[TRANSLATION]?.[currentLanguage]
    },
    [DEFAULT_LANGUAGE_TRANSLATION]: (state) => {
      return state[TRANSLATION]?.[defaultLanguage]
    },
  },
}
