import {
  dispatchAction,
  getState,
  getGetter,
  commitMutation,
  isEmpty,
  openLoginModal,
  load,
} from '@/helpers'
import {
  Module,
  RouteName,
  UPDATE_FAVOURITE_GAME_DICTIONARY,
  UPDATE_RECENT_GAME_DICTIONARY,
  SET_SLIDE_LIST,
  UPDATE_VENDOR_DICTIONARY,
  SET_NEWSLETTER_DICTIONARY,
  UPDATE_PERSONAL_INFO,
  VENDOR_DICTIONARY,
  SET_GAME_PLAY_DICTIONARY,
  SET_DEPOSIT_METHOD_DICTIONARY,
  SET_LIMIT_DICTIONARY,
  SET_DOC_TYPE_DICTIONARY,
  DOC_TYPE_DICTIONARY,
  UPDATE_DOCUMENT_DICTIONARY,
  SET_WITHDRAWAL_METHOD_DICTIONARY,
  WITHDRAWAL_METHOD_DICTIONARY,
  UPDATE_GAME_DEMO_DICTIONARY,
  SHOW_MODAL,
  RESEND_CONFIRMATION_EMAIL_MODAL,
  FORGOT_PASSWORD_MODAL,
  UPDATE_GAME_INFO_DICITONARY,
  SET_REWARD_SLIDE_LIST,
  SET_OFFER_DICTIONARY,
  EMPTY_ARRAY,
  IS_MOBILE_SCREEN,
  TOGGLE_LOGIN_SIDE_MENU,
  TOGGLE_FORGOT_PASSWORD_SIDE_MENU,
  TOGGLE_REGISTER_SIDE_MENU,
  TOGGLE_RESEND_CONFIRM_SIDE_MENU,
  TOGGLE_PNP_ACCOUNT_SIDE_MENU,
  PNP_ACCOUNT_MODAL,
} from '@/constants'
import { Protector } from './protector'

export class Resolver {
  static async Dashboard(to, from, next) {
    Protector.protectByLogin({ to })

    await load([
      dispatchAction([Module.FAVOURITE_GAME, UPDATE_FAVOURITE_GAME_DICTIONARY]),
      dispatchAction([Module.RECENT_GAME, UPDATE_RECENT_GAME_DICTIONARY]),
    ])

    next()
  }
  static async Deposit(to, from, next) {
    Protector.protectByLogin({ to })

    await load([
      dispatchAction([Module.DEPOSIT_METHOD, SET_DEPOSIT_METHOD_DICTIONARY]),
    ])

    next()
  }
  static DepositHistory(to, from, next) {
    Protector.protectByLogin({ to })

    next()
  }
  static ForgotPassword(to, from, next) {
    const isMobileScreen = getGetter([Module.DEVICE, IS_MOBILE_SCREEN])

    isMobileScreen
      ? commitMutation([Module.MAIN, TOGGLE_FORGOT_PASSWORD_SIDE_MENU], true)
      : commitMutation([Module.MODAL, SHOW_MODAL], {
          component: FORGOT_PASSWORD_MODAL,
          isStrict: true,
        })

    next()
  }
  static async Game(to, from, next) {
    next()
  }
  static async GameDemo(to, from, next) {
    await load([
      dispatchAction([Module.GAME_DEMO, UPDATE_GAME_DEMO_DICTIONARY], {
        urlSlug: to.params.slug,
      }),
    ])

    next()
  }
  static async GameInfo(to, from, next) {
    await load([
      dispatchAction([Module.GAME_INFO, UPDATE_GAME_INFO_DICITONARY], {
        urlSlug: to.params.slug,
      }),
    ])

    next()
  }
  static GameLists(to, from, next) {
    next()
  }
  static async GamePlay(to, from, next) {
    Protector.protectByLogin({ to })

    await load([
      dispatchAction([Module.GAME_PLAY, SET_GAME_PLAY_DICTIONARY], {
        urlSlug: to.params.slug,
      }),
    ])

    next()
  }
  static async GameVendor(to, from, next) {
    const hasNoVendors = isEmpty(getState([Module.VENDOR, VENDOR_DICTIONARY]))

    await load([
      ...(hasNoVendors
        ? [dispatchAction([Module.VENDOR, UPDATE_VENDOR_DICTIONARY])]
        : EMPTY_ARRAY),
    ])

    next()
  }
  static async Home(to, from, next) {
    await load([dispatchAction([Module.SLIDE, SET_SLIDE_LIST])])

    next()
  }
  static Login(to, from, next) {
    const isMobileScreen = getGetter([Module.DEVICE, IS_MOBILE_SCREEN])

    isMobileScreen
      ? commitMutation([Module.MAIN, TOGGLE_LOGIN_SIDE_MENU], true)
      : openLoginModal()
    next()
  }
  static async Newsletter(to, from, next) {
    Protector.protectByLogin({ to })

    await load([dispatchAction([Module.NEWSLETTER, SET_NEWSLETTER_DICTIONARY])])

    next()
  }
  static async Offers(to, from, next) {
    await load([
      dispatchAction([Module.OFFER, SET_OFFER_DICTIONARY], {
        resetsData: true,
      }),
    ])

    next()
  }
  static async Platinclub(to, from, next) {
    Protector.protectByLogin({ to })

    next()
  }
  static PnpAccount(to, from, next) {
    const isMobileScreen = getGetter([Module.DEVICE, IS_MOBILE_SCREEN])

    isMobileScreen
      ? commitMutation([Module.MAIN, TOGGLE_PNP_ACCOUNT_SIDE_MENU], true)
      : commitMutation([Module.MODAL, SHOW_MODAL], {
          component: PNP_ACCOUNT_MODAL,
          isStrict: true,
        })

    next()
  }
  static async Registration(to, from, next) {
    const isMobileScreen = getGetter([Module.DEVICE, IS_MOBILE_SCREEN])

    isMobileScreen &&
      commitMutation([Module.MAIN, TOGGLE_REGISTER_SIDE_MENU], true)

    next()
  }
  static RegistrationConfirm(to, from, next) {
    openLoginModal()
    next()
  }
  static ResendConfirmationEmail(to, from, next) {
    const isMobileScreen = getGetter([Module.DEVICE, IS_MOBILE_SCREEN])

    isMobileScreen
      ? commitMutation([Module.MAIN, TOGGLE_RESEND_CONFIRM_SIDE_MENU], true)
      : commitMutation([Module.MODAL, SHOW_MODAL], {
          component: RESEND_CONFIRMATION_EMAIL_MODAL,
          isStrict: true,
        })

    next()
  }
  static async RewardsCarnival(to, from, next) {
    await load([dispatchAction([Module.SLIDE, SET_REWARD_SLIDE_LIST])])

    next()
  }
  static async User(to, from, next) {
    Protector.protectByLogin({ to })

    await load([dispatchAction([Module.USER, UPDATE_PERSONAL_INFO])])

    next()
  }
  static UserAccount(to, from, next) {
    Protector.protectByLogin({ to })

    next()
  }
  static UserAccountBalance(to, from, next) {
    Protector.protectByLogin({ to })

    next()
  }
  static UserAccountVerify(to, from, next) {
    Protector.protectByLogin({ to })

    next()
  }
  static UserChangePassword(to, from, next) {
    Protector.protectByLogin({ to })

    next()
  }
  static async UserDocuments(to, from, next) {
    Protector.protectByLogin({ to })

    const hasNoDocTypes = isEmpty(
      getState([Module.DOC_TYPE, DOC_TYPE_DICTIONARY]),
    )

    await load([
      hasNoDocTypes
        ? dispatchAction([Module.DOC_TYPE, SET_DOC_TYPE_DICTIONARY])
        : EMPTY_ARRAY,
      dispatchAction([Module.DOCUMENT, UPDATE_DOCUMENT_DICTIONARY]),
    ])

    next()
  }
  static UserEdit(to, from, next) {
    Protector.protectByLogin({ to })

    next()
  }
  static async UserGameRounds(to, from, next) {
    Protector.protectByLogin({ to })
    next()
  }
  static async UserLimits(to, from, next) {
    Protector.protectByLogin({ to })

    await load([dispatchAction([Module.LIMIT, SET_LIMIT_DICTIONARY])])

    next()
  }
  static async UserTransactions(to, from, next) {
    Protector.protectByLogin({ to })
    next()
  }
  static async Withdrawal(to, from, next) {
    // adding withdrawal step 1
    Protector.protectByLogin({ to })

    if (from.name !== RouteName.WITHDRAWAL_ADD) {
      await load([
        dispatchAction([
          Module.WITHDRAWAL_METHOD,
          SET_WITHDRAWAL_METHOD_DICTIONARY,
        ]),
      ])
    }

    next()
  }
  static async WithdrawalAdd(to, from, next) {
    // adding withdrawal step 2
    Protector.protectByLogin({ to })

    const hasNoWithdrawalMethods = isEmpty(
      getState([Module.WITHDRAWAL_METHOD, WITHDRAWAL_METHOD_DICTIONARY]),
    )

    hasNoWithdrawalMethods &&
      (await load([
        dispatchAction([
          Module.WITHDRAWAL_METHOD,
          SET_WITHDRAWAL_METHOD_DICTIONARY,
        ]),
      ]))

    next()
  }
}
