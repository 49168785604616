import { Currency, Module, CURRENT_LANGUAGE } from '@/constants'
import { pipe } from './pipeline/basic/pipe'
import { when } from './pipeline/basic/when'
import { always } from './pipeline/basic/always'
import { isString } from './pipeline/basic/isString'
import { getState } from './store'

const LOCALE = 'EN'
const CURRENCY = 'currency'
const turnIntoDecimalNumber = (number) => number * 0.01

export const formatNumberLocale = ({
  locale,
  currency = Currency.EUR,
  number,
  needsCoins = true,
  ...others
}) => {
  const _locale =
    locale || getState([Module.LANGUAGE, CURRENT_LANGUAGE]) || LOCALE
  const options = { style: CURRENCY, currency, ...others }

  return when(
    isFinite,
    pipe(
      when(isString, Number),
      when(always(needsCoins), turnIntoDecimalNumber),
      (number) => number.toLocaleString(_locale, options),
    ),
  )(number)
}
