import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App'
import router from './router'
import store from './store'
import { EventType, Digit, Key } from './constants'
import { createComponentTranslator } from './helpers/createComponentTranslator'
import { setFavicon } from './helpers/setFlavicon'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.directive(EventType.CLICK_OUTSIDE, {
  bind: function (element, binding, vnode) {
    element.clickOutsideEvent = function (event) {
      if (!(element == event.target || element.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }

    document.body.addEventListener(EventType.CLICK, element.clickOutsideEvent)
  },
  unbind: function (element) {
    document.body.removeEventListener(
      EventType.CLICK,
      element.clickOutsideEvent,
    )
  },
})

Vue.prototype.$createComponentTranslator = createComponentTranslator

window.app = new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')

window.addEventListener(EventType.KEYDOWN, (e) => {
  if (e.key === Key.F11 || e.keyCode === Digit.ONE_HUNDRED_TWENTY_TWO) {
    e.preventDefault()
    return false
  }
})

setFavicon()
