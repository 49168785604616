//NOTE: keep component names of modals in modalName
export const ABOUT_US = 'AboutUs'
export const ACCOUNT_VERIFICATION = 'AccountVerification'
export const ALL_CATEGORY_FILTER_ICON = 'AllCategoryFilterIcon'
export const ALL_PROVIDER_FILTER_ICON = 'AllProviderFilterIcon'
export const APP = 'App'
export const BACKOFFICE_SUPPORT_ACCESS = 'BackofficeSupportAccess'
export const BALOON = 'Balloon'
export const BALOON_ARROW = 'BalloonArrow'
export const BLUR_COVER = 'BLUR_COVER'
export const BONUS_CANCEL = 'BonusCancel'
export const BONUSES_ICON = 'Bonuses-Icon'
export const BREADCRUMBS = 'Breadcrumbs'
export const BUTTON_LOADER = 'ButtonLoader'
export const CHANGE_PASSWORD = 'ChangePassword'
export const CHECKBOX_FIELD = 'CheckboxField'
export const CLOSE_ICON = 'CloseIcon'
export const CLUB_CARD = 'ClubCard'
export const CLUB_STATUS_ICON = 'ClubStatusIcon'
export const COOKIE_ACCEPT = 'CookieAccept'
export const DASHBOARD = 'Dashboard'
export const DATE_FIELD = 'DateField'
export const DEPOSIT = 'Deposit'
export const PAY_AND_PLAY_SIDE_MENU = 'PayAndPlaySideMenu'
export const DEPOSIT_HISTORY = 'DepositHistory'
export const DROPDOWN = 'Dropdown'
export const DROPDOWN_ARROW = 'DropdownArrow'
export const FAVOURITE_ICON = 'FavouriteIcon'
export const EMAIL_ICON = 'EmailIcon'
export const FILE_FIELD = 'FileField'
export const FILTER_BY_FILTER_ICON = 'FilterByFilterIcon'
export const FLASH = 'Flash'
export const FOOTER = 'Footer'
export const FOOTER_NAVBAR = 'FooterNavbar'
export const FORGOT_PASSWORD_SIDE_MENU = 'ForgotPasswordSideMenu'
export const FORM_DATA = 'FormData'
export const FREE_SPINS_ICON = 'FreeSpinsIcon'
export const GAME_BOX_WITH_HOVER = 'GameBoxWithHover'
export const GAME_DEMO = 'GameDemo'
export const GAME_INFO = 'GameInfo'
export const GAME_LISTS = 'GameLists'
export const GAME_LISTS_VIEW = 'GameListView'
export const GAME_LIST_HORIZONTAL_SLIDER = 'GameListHorizontalSlider'
export const GAME_NOTIFICATIONS = 'GameNotifications'
export const GAME_PLAY = 'GamePlay'
export const GAME_PLAY_NAVIGATION = 'GamePlayNavigation'
export const GAME_SIDE_MENU = 'GameSideMenu'
export const GAME_VENDOR = 'GameVendor'
export const GLOBAL_LOADER = 'GlobalLoader'
export const HOME = 'Home'
export const HOME__GAME_LIST = 'Home_GameList'
export const HOME_ICON = 'HomeIcon'
export const HORIZONTAL_LIST = 'HorizontalList'
export const LANGUAGE_ICON_BASE = 'LanguageIconBase'
export const LANGUAGE_SELECT = 'LanguageSelect'
export const LOADER_COMPONENT = 'Loader'
export const LOADING_COMPONENT = 'Loading'
export const LOGIN = 'Login'
export const LOGIN_SIDE_MENU = 'LoginSideMenu'
export const MAIN_CONTENT = 'MainContent'
export const MAIN_MENU = 'MainMenu'
export const MAIN_MENU_GAME = 'MainMenuGame'
export const MAIN_MENU_GAME_CATEGORY = 'MainMenuGameCategory'
export const MAIN_MENU_LANGUAGE = 'MainMenuLanguage'
export const MESSAGE_COMPONENT = 'Message'
export const MODAL = 'Modal'
export const MODAL_BACKGROUND = 'ModalBackground'
export const MODAL_CONTENT_COMPONENT = 'ModalContent'
export const MONEY_COUNTER = 'MoneyCounter'
export const MONEY_FIELD = 'MoneyField'
export const MY_ACCOUNT_SIDE_MENU = 'MyAccountSideMenu'
export const MY_PROFILE_BUTTON = 'MyProfileButton'
export const NAVBAR = 'Navbar'
export const NAVBAR__ACCOUNT_BALANCE = 'NavbarAccountBalance'
export const NAVBAR__PAY_AND_PLAY_BUTTON = 'NavbarPayAndPlayButton'
export const NAVBAR__GAME_PANEL = 'NavbarGamePanel'
export const NAVBER__LOGIN_BUTTON = 'NavbarLoginButton'
export const NAVBAR__LOGO_BUTTON = 'NavbarLogoButton'
export const NAVBAR__MAIN_MENU_BUTTON = 'NavbarMainMenuButton'
export const NAVBAR__SEARCH_INPUT = 'NavbarSearchInput'
export const NAVBAR__REGISTER_BUTTON = 'NavbarRegisterButton'
export const NAVBAR__USER_PANEL = 'NavbarUserPanel'
export const NEED_HELP = 'NeedHelp'
export const NEW_GAMES = 'NewGames'
export const ORDER_BY_FILTER_ICON = 'OrderByFilterIcon'
export const PAGE_404 = 'Page404'
export const PAGINATOR = 'Paginator'
export const PLATINCLUB = 'Platinclub'
export const PLATIN_COIN_ICON = 'PlatinCoinIcon'
export const PNP_ACCOUNT_VIEW = 'PnpAccountView'
export const PRIVACY_POLICY = 'PrivacyPolicy'
export const PROMOTIONS = 'Promotions'
export const PROMOTION_VIEW = 'PromotionView'
export const PROFILE_ACCOUNT_BALANCE = 'ProfileAccountBalance'
export const PROVIDER_LIST_SLIDER = 'ProviderListSlider'
export const RATING = 'Rating'
export const RATING_EMPTY_ICON = 'RatingEmptyIcon'
export const RATING_FULL_ICON = 'RatingFullIcon'
export const RATING_HALF_ICON = 'RatingHalfIcon'
export const RATING_STARTS = 'RatingStarts'
export const REGISTER_SIDE_MENU = 'RegisterSideMenu'
export const REGISTRATION = 'Registration'
export const REGISTRATION_BOX = 'RegistrationBox'
export const REGISTRATION_CONFIRM = 'RegistrationConfirm'
export const REGISTRATION_FORM = 'RegistrationForm'
export const REGISTRATION_SUCCESS = 'RegistrationSuccess'
export const RESEND_CONFIRMATION = 'ResendConfirmation'
export const RESEND_CONFIRMATION_SIDE_MENU = 'ResendConfirmationSideMenu'
export const RESPONSIBLE_GAMING = 'ResponsibleGaming'
export const REWARD_BREADCRUMBS = 'RewardBreadcrumbs'
export const REWARD_CARD = 'RewardCard'
export const REWARD_CARNIVAL = 'RewardCarnival'
export const ROUTE_COMPONENT = 'Route'
export const SEARCH = 'Search'
export const SEARCH_RESULT = 'SearchResult'
export const SESSION_CONTROLLER = 'SessionController'
export const SHOW_ALL_ICON = 'Show-All-Icon'
export const SLIDE_ARROW = 'SlideArrow'
export const SLIDE_SHOW = 'SlideShow'
export const SIDE_MENU = 'SideMenu'
export const SUPPORT_COMPONENT = 'SupportComponent'
export const SUPPORT_SIDE_MENU = 'SupportSideMenu'
export const TAB_PANELS = 'TabPanels'
export const TERMS_OF_USE = 'TermsOfUse'
export const TEXT_FIELD = 'TextField'
export const TIME = 'Time'
export const TOGGLE_FIELD = 'ToggleField'
export const TOP_GAMES = 'TopGames'
export const TRANSPARENCY = 'Transparency'
export const UPDATE_PROFILE_BUTTON = 'UpdateProfileButton'
export const USER = 'User'
export const USER_ACCOUNT = 'UserAccount'
export const USER_ACCOUNT_MENU_LIST = 'UserAccountMenuList'
export const USER_GAME_ROUND_FORM = 'UserGameRoundForm'
export const USER_GAME_ROUNDS = 'UserGameRounds'
export const USER_MENU = 'UserMenu'
export const USER_EDIT = 'UserEdit'
export const USER_DOCUMENTS = 'UserDocuments'
export const USER_DOCUMENT_FORM = 'UserDocumentForm'
export const USER_LIMITS = 'UserLimits'
export const USER_LIMIT_EDIT = 'UserLimitEdit'
export const USER_NEWSLETTER = 'UserNewsletter'
export const USER_NOTIFICATIONS = 'UserNotifications'
export const USER_NOTIFICATION = 'UserNotification'
export const USER_NOTIFICATION_BUTTON = 'UserNotificationButton'
export const USER_NOTIFICATION_SIDE_MENU = 'UserNotificationSideMenu'
export const USER_SELF_EXCLUSION = 'UserSelfExclusion'
export const USER_TRANSACTIONS = 'UserTransactions'
export const WITHDRAWAL = 'Withdrawal'
export const WITHDRAWAL_ADD = 'WithdrawalAdd'
export const WITHDRAWAL_CANCEL = 'WithdrawalCancel'
export const WITHDRAWAL_HISTORY = 'WithdrawalHistory'
export const WITHDRAWAL_PANEL = 'WithdrawalPanel'
export const WITHDRAWAL_STATUS = 'WithdrawalStatus'
export const WRAPPER = 'Wrapper'
export const VERTICAL_BAR = 'VerticalBar'
export const VERTICAL_SCROLL_PAGINATOR = 'VerticalScrollPaginator'
